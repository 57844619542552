export interface FirebaseConfig {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
}

export interface FirebaseDeeplink {
    disabled: boolean;
    activate: string;
    forgotPassword: string;
}
export interface EnvironmentConfig {
    name: string;
    idURL: string;
    apiURL: string;
    authURL: string;
    webAppURL: string;
    appVersion?: string;
    cdnURL: string;
    domain: string;
    concurClientId: string;
    concurClientSecret: string;
    firebase: FirebaseConfig;
    deeplink: FirebaseDeeplink;
}

const currentEnv: EnvironmentConfig = {
    name: process.env.NODE_ENV,
    idURL: process.env.REACT_APP_BASE_ID_URL,
    apiURL: process.env.REACT_APP_BASE_API_URL,
    authURL: process.env.REACT_APP_BASE_AUTH_URL,
    webAppURL: process.env.REACT_APP_WEBAPP_URL,
    appVersion: process.env.REACT_APP_VERSION,
    cdnURL: process.env.REACT_APP_CDN_URL,
    domain: process.env.REACT_APP_DOMAIN,
    concurClientId: process.env.REACT_APP_CONCUR_CLIENT_ID,
    concurClientSecret: process.env.REACT_APP_CONCUR_CLIENT_SECRET,
    firebase: {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
    },
    deeplink: {
        disabled: false, // process.env.REACT_APP_ENVIRONMENT === 'production',
        activate: process.env.REACT_APP_FIREBASE_DEEPLINK_ACTIVATE,
        forgotPassword: process.env.REACT_APP_FIREBASE_DEEPLINK_FORGOT_PASSWORD,
    },
};

const envConfig = {
    currentEnv,
};

export default envConfig;
