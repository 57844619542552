import React, { FC, ReactElement, ReactNode, memo } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { IconCommon, IconLogo, IconService } from '../../resources/images';
import { iconToCdn } from '../../utils/images';

interface SvgIconProps {
    icon: IconLogo | IconCommon | IconService;
    size?: 'inherit' | 'small' | 'mediumSmall' | 'medium' | 'large' | 'xxlarge';
    color?: 'default' | 'white' | 'black' | 'blue' | 'lightBlue' | 'grey' | 'darkGrey' | 'red' | 'lightBlack';
    fill?: string;
    stroke?: string;
    width?: number;
    height?: number;
    minWidth?: number;
    minHeight?: number;
    alert?: boolean;
    overwritePathFill?: boolean;
    className?: string;
    fallback?: ReactNode;
}

const SvgIcon: FC<SvgIconProps> = ({
    icon,
    size = 'medium',
    color = 'default',
    fill,
    stroke,
    width,
    height,
    minWidth,
    minHeight,
    alert,
    overwritePathFill,
    className,
    fallback,
}): ReactElement => {
    const classes = useStyles();

    const src = iconToCdn(icon);

    let overwriteStyle = '';
    const customStyle: any = {};

    if (fill) {
        customStyle.fill = fill;
    }
    if (stroke) {
        customStyle.stroke = stroke;
    }
    if (width) {
        customStyle.width = width;
    }
    if (height) {
        customStyle.height = height;
    }
    if (minWidth) {
        customStyle.minWidth = minWidth;
    }
    if (minHeight) {
        customStyle.minHeight = minHeight;
    }

    if (overwritePathFill) {
        overwriteStyle = classes.overwritePathFill;
    }

    if (alert) {
        return (
            <>
                <SVG src={src} className={clsx(classes[size], classes[color], className)} style={customStyle} />
                <SVG src={iconToCdn(IconCommon.Information)} className={clsx(classes.red, classes.iconError)} />
            </>
        );
    }

    return (
        <SVG
            src={src}
            className={clsx(classes[size], classes[color], className, overwriteStyle)}
            style={customStyle}
            loader={<div className={classes[size]} style={customStyle} />}
        >
            {fallback}
        </SVG>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        small: {
            width: 16,
            height: 16,
        },
        mediumSmall: {
            width: 18,
            height: 18,
        },
        medium: {
            width: 20,
            height: 20,
        },
        large: {
            width: 24,
            height: 24,
        },
        xxlarge: {
            width: 34,
            height: 34,
        },
        inherit: {
            width: 'inherit',
            fill: 'inherit',
            stroke: 'inherit',
        },
        default: {
            fill: 'none',
            stroke: theme.palette.colors.icon.lightGrey,
        },
        black: {
            fill: theme.palette.colors.icon.primary,
            stroke: theme.palette.colors.icon.primary,
        },
        lightBlack: {
            fill: theme.palette.primary.light,
            stroke: theme.palette.primary.light,
        },
        blue: {
            fill: theme.palette.colors.background.primaryBtn,
            stroke: theme.palette.colors.background.primaryBtn,
        },
        lightBlue: {
            fill: theme.palette.colors.background.focusElement,
            stroke: theme.palette.colors.background.focusElement,
        },
        grey: {
            fill: theme.palette.colors.icon.lightGrey,
            stroke: theme.palette.colors.icon.lightGrey,
        },
        darkGrey: {
            fill: theme.palette.colors.text.label,
            stroke: theme.palette.colors.text.label,
        },
        red: {
            fill: theme.palette.colors.text.error,
            stroke: theme.palette.colors.text.error,
        },
        white: {
            fill: theme.palette.primary.contrastText,
            stroke: theme.palette.primary.contrastText,
        },
        overwritePathFill: {
            '& path': {
                fill: 'inherit',
            },
        },
        iconError: {
            position: 'relative',
            stroke: 'none',
            left: -5,
            top: -6,
            height: 10,
            width: 10,
        },
    }),
);

export default memo(SvgIcon);
