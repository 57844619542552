export enum IconLogo {
    Logo = 'logo',
    LogoEntreprise = 'logo_entreprise',
    LogoMarcel = 'logo_marcel',
}

export enum IconCommon {
    AlertCircle = 'alert_circle',
    AlertProfil = 'alert_profil',
    Badge = 'badge',
    BadgeOutline = 'badge_outline',
    Briefcase = 'briefcase',
    BriefcaseOutline = 'briefcase_outline',
    Calendar = 'calendar',
    CalendarClock = 'calendar_clock',
    CardOutline = 'card_outline',
    Check = 'check',
    ChevronDown = 'chevron_down',
    ChevronDown2 = 'chevron_down_2',
    ChevronRight = 'chevron_right',
    CircleCheck = 'circle_check',
    CircleCheckOff = 'circle_check_off',
    CircleClose = 'circle_close',
    CirclePlus = 'circle_plus',
    Clock = 'clock',
    ClockOutline = 'clock_outline',
    Close = 'close',
    CoinsStack = 'coins_stack',
    Debug = 'debug',
    Document = 'document',
    DownloadImport = 'download_import',
    DropOffPin = 'drop_off_pin',
    Email = 'email',
    Export = 'export',
    Eye = 'eye',
    EyeOff = 'eye_off',
    Filters = 'filters',
    FreeDestination = 'free_destination',
    Gauge = 'gauge',
    Gear = 'gear',
    Home = 'home',
    Information = 'information',
    InformationOutline = 'information_outline',
    Lock = 'lock',
    MapCarIcon = 'map_car_icon',
    MapLocationTarget = 'map_location_target',
    // MeetingPointPin = 'meeting_point_pin',
    MenuBurger = 'menu_burger',
    Mouse = 'mouse',
    Offline = 'offline',
    PageListing = 'page_listing',
    Pancarte = 'pancarte',
    PenEdit = 'pen_edit',
    Pin = 'pin',
    PickUpPin = 'pick_up_pin',
    PinLocation = 'pin_location',
    PlaneTakeOff = 'plane_take_off',
    Radio = 'radio',
    RadioChecked = 'radio_checked',
    RailroadTrain = 'railroad_train',
    SelfServiceOffers = 'self_service_offers',
    SelfServiceOffersMobile = 'self_service_offers_mobile',
    StarsFull = 'stars_full',
    StarsOutline = 'stars_outline',
    Switch = 'switch',
    Tick = 'tick',
    Trash = 'trash',
    User = 'user',
    UserOutline = 'user_outline',
    UserOutlineMultiple = 'user_outline_multiple',
    Warning = 'warning',
    WheelOutline = 'wheel_outline',
}

export enum IconService {
    Berline = 'berline',
    Classic = 'classic',
    Green = 'green',
    Moto = 'moto',
    Prestige = 'prestige',
    Van = 'van',
    Taxi = 'taxi',
}

export enum IconError {
    Error = 'error',
}
