import React, { FC, ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { paths } from '../navigation/routes/paths';
import SvgIcon from './icons/SvgIcon';
import useKeyboardActive from './hook/useKeyboardActive';

import packageJson from '../../package.json';
import { isWebView } from '../utils/webview';
import { IconLogo } from '../resources/images';

interface LogoViewProps {}

const LogoView: FC<LogoViewProps> = (props): ReactElement => {
    const classes = useStyles();
    const navigate = useNavigate();

    const goToHome = (): void => {
        navigate(paths.home);
    };

    const keyboardShown = useKeyboardActive();

    if (keyboardShown || isWebView()) {
        return null;
    }

    return (
        <div className={classes.icon} onClick={goToHome}>
            <Tooltip title={`V ${packageJson.version}`}>
                <div>
                    <SvgIcon icon={IconLogo.Logo} size="inherit" stroke="none" />
                </div>
            </Tooltip>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            top: 15,
            left: 64,
            position: 'absolute',
        },
    }),
);

export default LogoView;
