import React, { FC, ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';

interface TextButtonProps extends ButtonProps {
    title: string;
    onClick: () => void;
    iconElement?: JSX.Element;
}

const UnderlineHoverTextButton: FC<TextButtonProps> = (props): ReactElement => {
    const { title, onClick, iconElement, className, ...rest } = props;
    const classes = useStyles();

    return (
        <Button
            onClick={onClick}
            disableRipple={true}
            startIcon={iconElement && iconElement}
            className={clsx(classes.button, className)}
            {...rest}
        >
            {title}
        </Button>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: theme.palette.secondary.light,
            fontWeight: 400,
            fontSize: 16,
            letterSpacing: '0.2px',
            minWidth: 'unset',
            padding: '8px 0px',
            '&.MuiButtonBase-root:hover': {
                backgroundColor: 'transparent',
            },
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    }),
);

export default UnderlineHoverTextButton;
