import { useQuery, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from './axios';
import { paths } from '../../../navigation/routes/paths';
import { ReactQueryError } from '../../apiTypes';
import { types } from '../../modules/pendings';
import { toastError } from '../../../utils/toast';

export interface QueryInfos {
    name: string;
    path: string;
    type: string; //UNIQUE
    params?: any;
    method?: string;
}

//https://react-query.tanstack.com/reference/useQuery
export interface Options {
    variables?: any;
    select?: (data: any) => any; // selectors to return only a piece of data or data transformation
    manual?: boolean; // ???
    enabled?: boolean; // Set this to false to disable this query from automatically running.
    onError?: (e: any) => any;
    onSuccess?: (e: any) => any;
}

const axiosFetcher = async (queryInfos: QueryInfos): Promise<any> => {
    const { path, params, method = 'get' }: QueryInfos = queryInfos;
    console.log('QUERY - METHOD', method, 'URL:', path, 'PARAMS:', params);
    const { data } = await axiosInstance[method](path, { params });
    return data;
};

export function useMyQuery<T>(query, options: Options = { variables: undefined }): UseQueryResult<T, ReactQueryError> {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { variables, onSuccess, onError, ...reactQueryOptions } = options;

    const queryInfos: QueryInfos = query(variables);
    const { name, params, type }: QueryInfos = queryInfos;
    const queryKey = [];
    if (name) {
        queryKey.push(name);
    }
    if (Object.keys(params)?.length > 0) {
        queryKey.push(params);
    }

    return useQuery<T, ReactQueryError>(
        queryKey,
        async () => {
            dispatch({ type: types.START_PENDING_OPERATION, payload: { type: type } });
            return axiosFetcher(queryInfos);
        },
        {
            ...reactQueryOptions,
            onSuccess(data) {
                dispatch({ type: types.STOP_PENDING_OPERATION, payload: { type: type } });

                if (onSuccess) {
                    onSuccess(data);
                }
            },
            onError: (error: ReactQueryError) => {
                dispatch({ type: types.STOP_PENDING_OPERATION, payload: { type: type } });

                const { status, data } = error.response;

                if (String(status) === '412') {
                    navigate(paths.home);
                } else {
                    if (onError) {
                        onError(error);
                    } else {
                        toastError(error?.message ?? '');
                    }
                }
            },
            // we want toast msg on dev & silent err on othen env, need an error boundary if activated
            // useErrorBoundary: !configs?.appConfig?.isDev,
        },
    );
}

export function createQuery(name, path, variables, method = 'get'): Omit<QueryInfos, 'type'> {
    return { name, path, params: { ...variables }, method };
}
