/**
 * This file contains the Material UI theme for the project
 */

import { darken, lighten } from '@mui/material';
import colors from './colors';

require('typeface-roboto');

const theme = {
    spacing: 8,
    typography: {
        fontFamily: 'Roboto',
        fontSize: 16,
        letterSpacing: '0.2px',
        button: {
            height: 56,
            textTransform: 'none' as any,
        } as any,
        h1: {
            fontSize: 32,
            fontWeight: 500,
            color: colors.black,
            fontFamily: 'Madras',
            letterSpacing: '0.2px',
        },
        h2: {
            fontSize: 18,
            fontWeight: 700,
            letterSpacing: '0.2px',
        },
        h3: {
            fontSize: 16,
            fontWeight: 700,
            letterSpacing: '0.2px',
        },
        h4: {
            fontSize: 14,
            fontWeight: 700,
            letterSpacing: '0.2px',
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: 700,
            lineHeight: '22px',
            letterSpacing: '0.2px',
        },
        subtitle2: {
            fontSize: 14,
            fontWeight: 700,
            letterSpacing: '0.2px',
        },
        overline: {
            fontSize: 16,
            color: colors.labelGrey,
            textTransform: 'none' as any,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'unset',
            margin: 8,
            letterSpacing: '0.2px',
        },
    },
    palette: {
        primary: {
            main: colors.black,
            light: colors.lightBlack,
            contrastText: colors.white,
        },
        secondary: {
            main: colors.blue,
            light: colors.lightBlue,
            contrastText: colors.white,
        },
        background: {
            default: colors.brightGray,
            paper: colors.lightGray,
            primaryElement: colors.brightGray,
        },
        divider: colors.fillGrey,
        colors: {
            background: {
                primaryElement: colors.brightGray,
                secondaryElement: colors.lightGray,
                focusElement: colors.darkBlue,
                filledForm: colors.brightGray,
                separator: colors.lightGray,
                header: colors.lightBlue,
                appBar: colors.white,
                primaryBtn: colors.blue,
                hoverPrimaryBtn: lighten(colors.blue, 0.06),
                secondaryBtn: colors.brightGray,
                hoverSecondaryBtn: darken(colors.brightGray, 0.05),
                selected: colors.brightGray,
            },
            text: {
                primaryButton: colors.white,
                secondaryButton: colors.black,
                overlineButton: colors.black,
                error: colors.red,
                success: colors.green,
                contrast: colors.brightGray,
                label: colors.labelGrey,
            },
            icon: {
                primary: colors.black,
                lightGrey: colors.iconGrey,
            },
        },
    },
    mixins: {
        toolbar: {
            minHeight: 96,
        },
    },
};

export default theme;
