import envConfig from '../config/currentEnv';
import { IconCommon, IconError, IconLogo, IconService } from '../resources/images';

const logoEnv: string | undefined = process.env.REACT_APP_LOGO;

export const iconToCdn = (icon: IconLogo | IconCommon | IconService | IconError): string => {
    const srcBase = envConfig.currentEnv.cdnURL;
    let src = srcBase;

    if (Object.values(IconLogo).includes(icon as IconLogo)) {
        if (logoEnv) {
            src += `logos_client/${icon}_${logoEnv.replaceAll(/\s/g, '').toLocaleLowerCase()}`;
        } else {
            src += `logos_lecab/${icon}`;
        }
    } else if (Object.values(IconService).includes(icon as IconService)) {
        src += `resources/products/services/${icon}`;
    } else if (Object.values(IconError).includes(icon as IconError)) {
        src += `resources/products/errors/${icon}`;
    } else {
        src += `resources/products/icons/${icon}`;
    }
    src += '.svg';
    return src;
};
