import React, { FC, ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Space, { Type } from './layout/space';
import clsx from 'clsx';

interface InfoBoxProps {
    title?: string;
    titleIcon?: 'info';
    description: string;
    className?: string;
    align?: 'left' | 'right';
}

const InfoBox: FC<InfoBoxProps> = ({ title, titleIcon, description, className, align }): ReactElement => {
    const classes = useStyles();

    const renderIcon = () => {
        return <InfoIcon color="primary" fontSize="small" />;
    };

    return (
        <div className={clsx(classes.root, className, align ? classes[align] : null)}>
            {title && (
                <div className={classes.titleContainer}>
                    {titleIcon && (
                        <>
                            {renderIcon()}
                            <Space value={1} type={Type.horizontal} />
                        </>
                    )}
                    <Typography
                        variant="h1"
                        className={classes.infoBoxTitle}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <Space value={1} />
                </div>
            )}
            <Typography
                variant="subtitle1"
                className={classes.infoBoxDesc}
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            background:
                'linear-gradient(0deg, #DAE2FB, #DAE2FB), linear-gradient(0deg, rgba(69, 112, 234, 0.2), rgba(69, 112, 234, 0.2)), #FFFFFF',
            borderRadius: 6,
            padding: '20px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: theme.spacing(1),
            color: theme.palette.secondary.light,
        },
        left: {
            textAlign: 'start',
            alignItems: 'flex-start',
        },
        right: {
            textAlign: 'end',
            alignItems: 'flex-end',
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        infoBoxTitle: {
            fontSize: 14,
            fontWeight: 400,
        },
        infoBoxDesc: {
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.primary.main,
        },
    }),
);

export default InfoBox;
