import React, { FC, ReactElement } from 'react';
import { StyledEngineProvider, Theme, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import RootContainer from './routes/rootContainer';
import ModalContainer from './modalContainer';
import theme from '../resources/style/theme';

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

const appTheme: Theme = createTheme(theme);

interface AppContainerProps {}

const AppContainer: FC<AppContainerProps> = (): ReactElement => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
                <CssBaseline />
                <ModalContainer />
                <RootContainer />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default AppContainer;
