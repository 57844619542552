import React, { FC, ReactElement } from 'react';
// Components
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { createStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Space, { Type } from '../layout/space';
import UnderlineHoverTextButton from '../buttons/underlineHoverButton';
// Ressources
import stringsRes from '../../resources/strings';
import TextButton from '../buttons/textButton';
import InfoBox from '../infoBox';
import SvgIcon from '../icons/SvgIcon';
import { IconCommon, IconLogo } from '../../resources/images';
import { isWebView } from '../../utils/webview';

interface PropsFromModal {
    onRequestClose?: () => void;
}

interface MarcelProps extends PropsFromModal {
    title?: string;
    description?: string;
    labelCancel?: string;
    hasCancel?: boolean;
    labelConfirm?: string;
    onConfirmAction?: () => void;
    onCancelAction?: () => void;
}

const Marcel: FC<MarcelProps> = (props): ReactElement => {
    const {
        onRequestClose,
        title = stringsRes.login.external.popup.title,
        description = stringsRes.login.external.popup.details.title,
        labelCancel,
        hasCancel = true,
        labelConfirm,
        onConfirmAction,
        onCancelAction,
    } = props;
    const classes = useStyle();
    const isWeb = !isWebView();
    const details = [
        stringsRes.login.external.popup.details.info1,
        stringsRes.login.external.popup.details.info2,
        stringsRes.login.external.popup.details.info3,
        stringsRes.login.external.popup.details.info4,
    ];

    const onClickConfirm = (): void => {
        if (onConfirmAction) {
            onConfirmAction();
        }
        onRequestClose();
    };

    const onClickCancel = (): void => {
        if (onCancelAction) {
            onCancelAction();
        }
        onRequestClose();
    };

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <div className={classes.titleContainer}>
                    <Typography variant="h1" className={classes.title}>
                        {isWeb ? stringsRes.login.external.info.web : title}
                    </Typography>
                    <span onClick={onClickCancel} className={classes.closeIcon}>
                        <SvgIcon icon={IconCommon.Close} color="black" size="large" />
                    </span>
                </div>
                <Space value={2} />
                <div className={classes.logoContainer}>
                    <SvgIcon icon={IconLogo.LogoMarcel} size="inherit" stroke="none" />
                    <ArrowForwardIcon />
                    <SvgIcon icon={IconLogo.Logo} size="inherit" stroke="none" />
                </div>
                <Space type={Type.vertical} value={2} />
                <Typography variant="h4" className={classes.h4}>
                    {description}
                </Typography>
                <ul className={classes.ul}>
                    {details.map((d) => (
                        <li key={d}>{d}</li>
                    ))}
                </ul>
            </div>

            <Space type={Type.vertical} value={1} />
            <InfoBox
                align="left"
                title={isWeb ? stringsRes.login.external.popup.webTitle : stringsRes.login.external.popup.infoTitle}
                titleIcon="info"
                description={
                    isWeb
                        ? stringsRes.login.external.popup.webDescription
                        : stringsRes.login.external.popup.infoDescription
                }
            />
            {!isWeb ? (
                <div className={classes.buttonContainer}>
                    <TextButton
                        title={labelConfirm ?? stringsRes.login.external.popup.download}
                        onClick={onClickConfirm}
                    />
                    {hasCancel && (
                        <UnderlineHoverTextButton
                            title={labelCancel ?? stringsRes.login.external.popup.later}
                            className={classes.button}
                            onClick={onClickCancel}
                        />
                    )}
                </div>
            ) : (
                <Space type={Type.vertical} value={3} />
            )}
        </div>
    );
};

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginRight: theme.spacing(3),
            marginLeft: theme.spacing(3),
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            justifyContent: 'center',
            marginTop: theme.spacing(3),
        },
        titleContainer: {
            display: 'flex',
            placeItems: 'center',
            justifyContent: 'space-between',
        },
        title: {
            fontSize: 26,
        },
        closeIcon: {
            alignSelf: 'baseline',
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default,
            borderRadius: 10,
            columnGap: 18,
            padding: '20px 0px',
        },
        h4: {
            fontWeight: 900,
            fontFamily: 'Madras',
        },
        ul: {
            fontSize: 14,
            color: theme.palette.colors.text.label,
        },
        buttonContainer: {
            display: 'flex',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
            flexDirection: 'column',
            justifyContent: 'center',
        },
        button: {},
    }),
);

export default Marcel;
