import React, { FC, ReactElement } from 'react';
import { Theme } from '@mui/material/styles';

import { createStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';

export enum Type {
    horizontal = 'horizontal',
    vertical = 'vertical',
}

interface SpaceProps {
    type?: Type;
    value?: number;
}

const Space: FC<SpaceProps> = (props): ReactElement => {
    const { type = Type.vertical, value = 0.5 } = props;
    const classes = useStyles(value)({});
    const activeClasses: string = type === Type.horizontal ? classes.horizontal : classes.vertical;

    return <div className={activeClasses} />;
};

const useStyles = (spaceValue: number) =>
    makeStyles((theme: Theme) =>
        createStyles({
            horizontal: {
                width: theme.spacing(spaceValue),
            },
            vertical: {
                height: theme.spacing(spaceValue),
            },
        }),
    );

export default Space;
