import React, { FC, ReactElement } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isWebView } from '../../utils/webview';
import { getTopBarHeight } from '../../localStorageAPI';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

interface ToastProviderProps {}

const ToastProvider: FC<ToastProviderProps> = (props): ReactElement => {
    const classes = useStyles({
        isWebView: isWebView(),
        statusBarHeight: getTopBarHeight() || 0,
    });

    return (
        <ToastContainer
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            closeButton={false}
            theme="colored"
            className={classes.toastProviderContainer}
            position="top-center"
            draggableDirection="y"
            draggablePercent={50}
        />
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toastProviderContainer: (props: { isWebView: boolean; statusBarHeight: number }) => ({
            '--toastify-color-info':
                'linear-gradient(0deg, #DAE2FB, #DAE2FB), linear-gradient(0deg, rgba(69, 112, 234, 0.2), rgba(69, 112, 234, 0.2)), #FFFFFF',
            '--toastify-color-success': 'linear-gradient(0deg, rgba(0, 220, 0, 0.12), rgba(0, 220, 0, 0.12)), #FFFFFF',
            // '--toastify-color-warning': '#f1c40f',
            '--toastify-color-error': 'linear-gradient(0deg, rgba(220, 0, 0, 0.12), rgba(220, 0, 0, 0.12)), #FFFFFF',

            '--toastify-text-color-info': theme.palette.secondary.light,
            '--toastify-text-color-success': theme.palette.colors.text.success,
            // '--toastify-text-color-warning': '#fff',
            '--toastify-text-color-error': theme.palette.colors.text.error,

            '--toastify-toast-width': 'auto',
            '--toastify-toast-min-height': 52,
            '--toastify-font-family': 'Madras',

            '&.Toastify__toast-container--top-center': {
                top: `${props.statusBarHeight}px`,
            },
            '&.Toastify__toast-container': {
                fontSize: 16,
                fontWeight: 900,
                padding: props.isWebView ? 20 : 0,
            },
            '& .Toastify__toast-body': {
                padding: '8px 16px',
            },
            '& .Toastify__toast': {
                borderRadius: 10,
            },
            '& .Toastify__toast-icon': {
                marginInlineEnd: 12,
            },
        }),
    }),
);

export default ToastProvider;
